<template>
  <div class="content">
    <moreHeader></moreHeader>
    <div class="header">
      <h1>智慧警务</h1>
      <p style="text-indent: 2em">
        “智慧警务”是综合运用现代科学技术，整合公安信息资源，统筹公安业务应用系统，促进公安建设和公安执法、管理与服务的科学发展。其要旨是汇聚人的智慧，赋予物以智能，使汇集智慧的人与具备智能的物互存互动、互补互促，以实现公安效益最优化。它标志着公安信息化正在走向数字化、网络化、智能化三者的高度融合———智慧化。
      </p>
    </div>
    <div class="framework">
      <h2 class="title">平台架构</h2>
      <div class="img3">
        <img
          style="width: 100%; height: 100%"
          @click="onYuLan(img)"
          :src="img"
          alt=""
        />
      </div>
    </div>
    <h2 class="title">功能场景</h2>
    <div class="scene">
      <van-tabs
        v-model="active"
        swipeable
        :swipe-threshold="3"
        title-active-color="#365EF1"
        background="#F2F3F8"
      >
        <van-tab v-for="(item, index) in list" :key="index" :title="item.name">
          <img :src="item.img" alt="" class="sceneImg" />
          <h1 class="title">{{ item.name }}</h1>
          <div v-if="item.data">
            <div v-for="(el, i) in item.data" :key="i">
              <div class="classification">{{ el }}</div>
            </div>
          </div>
          <div v-if="item.text1">
            <p>{{ item.text1 }}</p>
            <p>{{ item.content }}</p>
          </div>
          <p v-else>{{ item.text }}</p>
          <div class="detail" @click="go(item.id)">查看详情</div>
        </van-tab>
      </van-tabs>
    </div>
  </div>
</template>

<script>
import moreHeader from "../../components/header";
import { ImagePreview } from "vant";
export default {
  components: { moreHeader },
  name: "",
  data() {
    return {
      img: require("../../assets/image/fbiImg/组 264.png"),
      active: 0,
      list: [
        {
          id: 0,
          name: "云监控",
          img: require("../../assets/assets/fbiImg/img2.png"),
          text: "云监控系统主要针对现有视频监控难以覆盖的内部区域和室外公共区域，通过部署支持无线WIFI的萤石、乐橙等摄像机，实现视频监控的无缝接入，实现视频监控的全方位、无死角覆盖。",
        },
        {
          id: 1,
          name: "指挥调度",
          img: require("../../assets/assets/fbiImg/img3.png"),
          text: "对电、水、气、热（冷）量等集中采集与分析，对企业端各类能耗进行细分和统计，展示各类能源的使用消耗情况，通过精细化的管理找出高耗能点或不合理的耗能习惯，有效节约能源，为用户进一步节能改造或设备升级提供准确的数据支撑",
        },
        {
          id: 2,
          name: "九小场所管理",
          img: require("../../assets/assets/fbiImg/img4.png"),
          text: "系统汇集了各种基础要素，方便警员可快速查看本辖区的基础要素概况与详细情况，有效提升辖区重点区域防控。",
        },
        {
          id: 3,
          name: "数据融合与共享",
          img: require("../../assets/assets/fbiImg/img5.png"),
          text: "支持多种物联网设备的数据接入，如监控设备、消防设备、电梯设备、闸口设备等；也能直接从政府、企业的各类数据平台拉取数据，所聚合的数据，可以快速共享到其他平台。",
        },
      ],
    };
  },

  methods: {
    onYuLan(img) {
      console.log(img);
      ImagePreview([img]);
    },
    go(id) {
      this.$router.push({
        path: "/m_fbiDetail",
        query: {
          fbiId: id,
        },
      });
    },
  },
};
</script>

<style scoped lang="less">
.content {
  box-sizing: border-box;
  margin-top: -27px;
  .title {
    font-size: 18px;
    font-family: PingFang SC;
    font-weight: 600;
    color: #333333;
    text-align: center;
    padding: 20px 0;
  }
  .header {
    height: 480px;
    background: url("../../assets/assets/fbiImg/bg.png") no-repeat;
    background-size: 100% 100%;
    padding-left: 10px;
    > h1 {
      font-size: 20px;
      font-weight: normal;
      color: #fff;
      padding-top: 100px;
      margin: 0;
    }
    > p {
      padding: 20px 10px 0 0px;
      font-size: 16px;
      font-family: PingFang SC;
      font-weight: 300;
      line-height: 30px;
      color: #ffffff;
    }
  }
  .framework {
    .img3 {
      width: calc(100% - 20px);
      height: 260px;
      background: url("../../assets/image/fbiImg/组 264.png") no-repeat;
      background-size: contain;
      margin: 0px 0 0 10px;
    }
  }
  .scene {
    width: calc(100% - 20px);
    margin-left: 10px;
    background-color: #fff;
    padding-bottom: 20px;

    .sceneImg {
      width: 100%;
      height: 180px;
      padding-top: 20px;
      background-color: #f2f3f8;
    }
    p {
      text-indent: 2em;
      font-size: 14px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #555555;
      padding: 0 10px;
    }
    .detail {
      width: 100px;
      height: 36px;
      line-height: 36px;
      text-align: center;
      border: 1px solid rgba(54, 94, 241, 0.5);
      border-radius: 36px;
      font-size: 14px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #333333;
      opacity: 1;
      position: relative;
      left: 0;
      right: 0;

      margin: auto;
      margin-top: 20px;
    }
    h4 {
      font-size: 14px;
      text-align: center;
      color: #333;
    }
    .classification {
      font-size: 14px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #555555;
      text-align: center;
    }
  }
}
::v-deep .van-tabs__line {
  background-color: #365ef1;
}
</style>
